<template>
	<!-- nav -->
	<!-- [D] 상단 고정 메뉴일 경우 nav--fixed 클래스 추가 -->
	<nav class="nav nav--fixed">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left--white">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text white">
					액티비티
				</span>
			</p>
			<a class="nav__btn nav__btn--map"></a>
		</div>
	</nav>
	<!-- //nav -->
    <div id="container" class="container container--fit">
		<div id="sub" class="sub leisure">
			<!-- gallery -->
			<section class="gallery">
				<div class="gallery__slider gallery__slider--noinfo">
					<div class="gallery__image">
						<img src="@/assets/images/templeisure01.jpg" alt="역삼벤자민모텔">
					</div>
				</div>
			</section>
			<!-- contact -->
			<section class="content">
				<div class="box">
					<div class="detail__wrap">
						<!-- 위치 정보 -->
						<div class="detail__header detail__header--small">
							<div class="nav__wrap">
								<p class="nav__item">
								<span class="nav__btn">
									<i class="icon icon-location"></i>
								</span>
									<span class="nav__text">
									충북전체
								</span>
								</p>
								<a href="#" class="nav__btn nav__btn--map">
									<i class="icon icon-map"></i>
								</a>
							</div>
						</div>
						<div class="detail__body">
							<!-- 숙소 추가 정보 -->
                            <div class="list__meta">
                                <div class="list__check">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" class="chk blind">
                                            <span class="checkbox__bg"></span>
                                            쿠폰할인 가능한 숙소
                                        </label>
                                    </div>
                                </div>
                                <div class="list__option">
                                    <button type="button">
                                        <i class="icon icon-align">
                                            정렬
                                        </i>
                                    </button>
                                </div>
                            </div>
						</div>
					</div>
				</div>
				<div class="detail__wrap">
					<ul class="list list--full">
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
                                            <span class="badge__text">
                                                1만원 할인쿠폰
                                            </span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼벤자민모텔">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													패러글라이딩 체험비행 명가
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												[충북] 단양 패러글라이딩
											</h3>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-desc">
													당일사용가능 <br>
													미사용 전액 환불
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__sale">
														<strong>14%</strong>
														<span>35,000</span>
													</div>
													<div class="info-price__current">
														<strong>25,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</router-link>
								<ul class="item__menu">
									<li>
										<a href="#">
											<i class="icon icon-pick--point2">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
                                        <span class="badge__text">
                                            1만원 할인쿠폰
                                        </span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼벤자민모텔">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													패러글라이딩 체험비행 명가
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title">
												[충북] 단양 패러글라이딩
											</h3>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-desc">
													당일사용가능 <br>
													미사용 전액 환불
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__sale">
														<strong>14%</strong>
														<span>35,000</span>
													</div>
													<div class="info-price__current">
														<strong>25,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</router-link>
								<ul class="item__menu ">
									<li>
										<a href="#">
											<i class="icon icon-pick--point2">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>